// ------------------------------------------------------------------------------------------------
// 3rd Party Modules
// ------------------------------------------------------------------------------------------------
import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql, Link } from 'gatsby';
// ------------------------------------------------------------------------------------------------
// Custom Modules
// ------------------------------------------------------------------------------------------------
import Layout from 'components/Layout';
import { seoContext } from 'context/seo-context';

import './about.scss';
// ------------------------------------------------------------------------------------------------
const About: React.FC = () => {
  const data = useStaticQuery(graphql`
    query AboutQuery {
      vinicio: file(relativePath: { eq: "vinicio-sanchez.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      leap: file(relativePath: { eq: "vinicio-sanchez-leap.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 754, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      codeFellows: file(relativePath: { eq: "vinicio-sanchez-code-fellows.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 754, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cadejo: file(relativePath: { eq: "cadejo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 754, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kaliKhal: file(relativePath: { eq: "kali-and-khal.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 754, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <seoContext.Provider value={{ title: 'About' }}>
      <Layout className="about-page">
        <article>
          <section className="section about">
            <div className="container">
              <div className="introduction m-0">
                <div className="introduction__picture">
                  <Img fluid={data.vinicio.childImageSharp.fluid} alt="Vinicio Sanchez's picture" />
                </div>
                <div className="introduction__body content">
                  <h1>Hello There!</h1>
                  <p>
                    My name is Vinicio Vladimir Sanchez Trejo (you can call me Vinicio{' '}
                    <span role="img" aria-label="Laughing Emoji">
                      😂
                    </span>
                    ), and one of my main goals in life is to help you change your life through computer science.
                  </p>
                  <p>If you are interested in coding, here are some of the goals I have for you:</p>
                  <ul>
                    <li>I want you to learn how to code - the right way - and become a great developer.</li>
                    <li>I want you to go and crush technical interviews.</li>
                    <li>I want you to get an amazing job in the tech industry.</li>
                    <li>
                      I want you to get your <em>next</em> amazing job or get that juicy raise or build that app you
                      have been dreaming about.
                    </li>
                    <li>
                      Above all though, I would like you to fall in love with the beautiful field of computer science.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <div className="section section-content">
            <div className="container">
              <div className="center content">
                <h2>But Why?</h2>
                <p>That&apos;s an excellent question. I&apos;m glad you asked!</p>
                <p>
                  I knew that I wanted to teach Computer Science since I was in high-school. In university, I realized
                  that my favorite teachers were always the ones that taught and also had a job in the industry. I
                  decided to follow in their footsteps.
                </p>
                <p>
                  After a couple of years of working for Microsoft, I made one of my dreams come true and became an
                  instructor at{' '}
                  <a href="https://www.codefellows.org/" target="_blank" rel="noopener noreferrer">
                    Code Fellows
                  </a>{' '}
                  - a Seattle-based Code School. I was even lucky enough to go back to Microsoft and teach its{' '}
                  <a href="https://www.microsoft.com/en-us/leap/" target="_blank" rel="noopener noreferrer">
                    LEAP
                  </a>{' '}
                  program two times in a row!
                </p>
                <Img
                  fluid={data.leap.childImageSharp.fluid}
                  alt="Vinicio Sanchez's picture with a Microsoft's LEAP Cohort"
                />
                <p>
                  I initially became an instructor to fulfill one of <em>my</em> personal goals, but while I was
                  teaching Computer Science, I learned some key things about the tech industry:
                </p>
                <ul>
                  <li>
                    Computer Science can change people&apos;s lives! It made me so happy to see how students with no
                    previous experience landed jobs in companies like Amazon or Microsoft. It made me even happier to
                    realize I was helping :)
                  </li>
                  <li>
                    If you want to be in the tech industry, you should have, and deserve a place. Your background and
                    diversity is not a liability but an asset.
                  </li>
                  <li>
                    You don&apos;t need a degree to start in the industry. A degree is very very useful, but you
                    don&apos;t <em>need</em> it.
                  </li>
                  <li>
                    Getting into the tech industry takes a great amount of hard work, but it&apos;s worthwhile. Look at
                    these happy students from one of my classes at Code Fellows!{' '}
                  </li>
                </ul>
                <Img
                  fluid={data.codeFellows.childImageSharp.fluid}
                  alt="Vinicio Sanchez's picture with a Code Fellows Cohort"
                />
                <h2>Why Cadejo.dev?</h2>
                <p>
                  After a couple of years teaching people how to code, I decided to go back to the industry and became a
                  developer at Liquid Planner; however, there is still a lot of teaching left in me, so I created
                  Cadejo.dev to continue my teaching endeavors!
                </p>
                <p>
                  A &quot;Cadejo&quot; is a mythological creature from my home country: El Salvador. It&apos;s supposed
                  to aid travelers in their journeys. I thought it was a fitting name for the website since I aim to aid
                  you in your coding adventures. I&apos;ll leave you with a Cadejo&apos;s drawing by{' '}
                  <a href="https://www.deviantart.com/renathory" target="_blank" rel="noopener noreferrer">
                    Renathory
                  </a>
                </p>
                <Img fluid={data.cadejo.childImageSharp.fluid} alt="Cadejos' drawing by Renathory" />
                <p>
                  So what say you? Do you want to learn Computer Science together and change our lives? Subscribe to my{' '}
                  <Link to="/about#footer-newsletter">newsletter</Link> and let&apos;s get this party started!
                </p>
                <h2 id="about-kali-and-khal">About Kali and Khal</h2>
                <p>
                  If you spend a little bit of time on this site, you&apos;ll see I use the name Kali and Khal all the
                  time in my code samples, so I thought it would be a good idea to introduce you to them.
                </p>
                <p>
                  Khaleesi is the dog. I call her Kali. She&apos;s a shy but sweet girl. Khal Basil is the cat.
                  He&apos;s affectionate and playful. I&apos;m happy to have them as part of my Ka-tet.
                </p>
                <Img fluid={data.kaliKhal.childImageSharp.fluid} alt="My pets: Kali and Khal" />
              </div>
            </div>
          </div>
        </article>
      </Layout>
    </seoContext.Provider>
  );
};

export default About;
